import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
          <div id="menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <StaticQuery
                query={graphql`
                  query PageMenuQuery {
                    allMarkdownRemark(filter: {fields: {collection: {eq: "pages"}}}) {
                      edges {
                        node {
                          fields {
                            slug
                            collection
                          }
                          frontmatter {
                            title
                          }
                        }
                      }
                    }
                    }
                `}
                render={data => (
                  data.allMarkdownRemark.edges.map(({node}) => (
                    <li>
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                    </li>
                  ))
                )}
              />
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
